import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _41c45f1c = () => interopDefault(import('../pages/wrapper.vue' /* webpackChunkName: "wrapper" */))
const _cd863b84 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "careers" */))
const _2029e94b = () => interopDefault(import('../pages/teltonika-ems.com-landing.vue' /* webpackChunkName: "teltonika-ems.com-landing" */))
const _71380443 = () => interopDefault(import('../pages/electronics-assembly.vue' /* webpackChunkName: "electronics-assembly" */))
const _4116c95b = () => interopDefault(import('../pages/electronics-design.vue' /* webpackChunkName: "electronics-design" */))
const _90b558e6 = () => interopDefault(import('../pages/ems-rma.vue' /* webpackChunkName: "ems-rma" */))
const _56a47c2c = () => interopDefault(import('../pages/manufacturingJourney/index.vue' /* webpackChunkName: "manufacturingJourney" */))
const _73ff5b1d = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "news" */))
const _2673e8af = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "search" */))
const _928d5fe4 = () => interopDefault(import('../pages/dynamic-newsletter.vue' /* webpackChunkName: "dynamic-newsletter" */))
const _ea1bd202 = () => interopDefault(import('../pages/defaultContent.vue' /* webpackChunkName: "null" */))
const _43c793cc = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "contacts" */))
const _17157922 = () => interopDefault(import('../pages/mission-vision-values.vue' /* webpackChunkName: "mission-vision-values" */))
const _89ee1394 = () => interopDefault(import('../pages/policiesCertificates/index.vue' /* webpackChunkName: "policiesCertificates" */))
const _167ccd54 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "about" */))
const _f4e2d386 = () => interopDefault(import('../pages/warranty-and-repair.vue' /* webpackChunkName: "warranty-and-repair" */))
const _8d5b5624 = () => interopDefault(import('../pages/policiesCertificates/_slug.vue' /* webpackChunkName: "policiesCertificates" */))
const _54eddae4 = () => interopDefault(import('../pages/manufacturingJourney/_slug.vue' /* webpackChunkName: "manufacturingJourney" */))
const _7248b9d5 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "news" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus","de":"\u002Fabout-us","fr":"\u002Fabout-us","it":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___en"
  }, {
    path: "/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera","de":"\u002Fcareer","fr":"\u002Fcareer","it":"\u002Fcareer"},"page_id":21},
    name: "career-21___en"
  }, {
    path: "/de",
    component: _2029e94b,
    meta: {"paths":{"en":"\u002F","lt":"\u002F","de":"\u002F","fr":"\u002F","it":"\u002F"},"page_id":6},
    name: "teltonika-ems.com-landing-6___de"
  }, {
    path: "/electronics-assembly",
    component: _71380443,
    meta: {"paths":{"en":"\u002Felectronics-assembly","lt":"\u002Fkontraktine-gamyba","de":"\u002Felectronics-assembly","fr":"\u002Felectronics-assembly","it":"\u002Felectronics-assembly"},"page_id":39},
    name: "electronics-assembly-39___en"
  }, {
    path: "/electronics-design",
    component: _4116c95b,
    meta: {"paths":{"en":"\u002Felectronics-design","lt":"\u002Felektronikos-dizainas","de":"\u002Felectronics-design","fr":"\u002Felectronics-design","it":"\u002Felectronics-design"},"page_id":38},
    name: "electronics-design-38___en"
  }, {
    path: "/ems-rma",
    component: _90b558e6,
    meta: {"paths":{"en":"\u002Fems-rma","lt":"\u002Fems-rma","de":"\u002Fems-rma","fr":"\u002Fems-rma","it":"\u002Fems-rma"},"page_id":44},
    name: "ems-rma-44___en"
  }, {
    path: "/fr",
    component: _2029e94b,
    meta: {"paths":{"en":"\u002F","lt":"\u002F","de":"\u002F","fr":"\u002F","it":"\u002F"},"page_id":6},
    name: "teltonika-ems.com-landing-6___fr"
  }, {
    path: "/it",
    component: _2029e94b,
    meta: {"paths":{"en":"\u002F","lt":"\u002F","de":"\u002F","fr":"\u002F","it":"\u002F"},"page_id":6},
    name: "teltonika-ems.com-landing-6___it"
  }, {
    path: "/lt",
    component: _2029e94b,
    meta: {"paths":{"en":"\u002F","lt":"\u002F","de":"\u002F","fr":"\u002F","it":"\u002F"},"page_id":6},
    name: "teltonika-ems.com-landing-6___lt"
  }, {
    path: "/manufacturing",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fmanufacturing","lt":"\u002Fgamyba","de":"\u002Fmanufacturing","fr":"\u002Fmanufacturing","it":"\u002Fmanufacturing"},"page_id":40},
    name: "manufacturing-40___en"
  }, {
    path: "/manufacturing-journey",
    component: _56a47c2c,
    meta: {"paths":{"en":"\u002Fmanufacturing-journey","lt":"\u002Felektronikos-gamybos-kelias","de":"\u002Fmanufacturing-journey","fr":"\u002Fmanufacturing-journey","it":"\u002Fmanufacturing-journey"},"page_id":180},
    name: "manufacturing-journey-180___en"
  }, {
    path: "/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1","de":"\u002Fnews","fr":"\u002Fnews","it":"\u002Fnews"},"page_id":7},
    name: "news-7___en"
  }, {
    path: "/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos","de":"\u002Fnewsroom","fr":"\u002Fnewsroom","it":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___en"
  }, {
    path: "/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai","de":"\u002Fresources","fr":"\u002Fresources","it":"\u002Fresources"},"page_id":28},
    name: "resources-28___en"
  }, {
    path: "/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska","de":"\u002Fsearch","fr":"\u002Fsearch","it":"\u002Fsearch"},"page_id":45},
    name: "search-45___en"
  }, {
    path: "/services",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fservices","lt":"\u002Fpaslaugos","de":"\u002Fservices","fr":"\u002Fservices","it":"\u002Fservices"},"page_id":29},
    name: "services-29___en"
  }, {
    path: "/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok","de":"\u002Fsubscribe","fr":"\u002Fsubscribe","it":"\u002Fsubscribe"},"page_id":67},
    name: "subscribe-67___en"
  }, {
    path: "/subscribe-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old","de":"\u002Fsubscribe-old","fr":"\u002Fsubscribe-old","it":"\u002Fsubscribe-old"},"page_id":13},
    name: "subscribe-old-13___en"
  }, {
    path: "/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___en"
  }, {
    path: "/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","de":"\u002Fabout-us\u002Fcontacts","fr":"\u002Fabout-us\u002Fcontacts","it":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___en"
  }, {
    path: "/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","de":"\u002Fabout-us\u002Fmission-vision-values","fr":"\u002Fabout-us\u002Fmission-vision-values","it":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___en"
  }, {
    path: "/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","de":"\u002Fabout-us\u002Fpolicies-certificates","fr":"\u002Fabout-us\u002Fpolicies-certificates","it":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___en"
  }, {
    path: "/about-us/who-we-are",
    component: _167ccd54,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fkas-mes-esame","de":"\u002Fabout-us\u002Fwho-we-are","fr":"\u002Fabout-us\u002Fwho-we-are","it":"\u002Fabout-us\u002Fwho-we-are"},"page_id":37},
    name: "about-us-who-we-are-37___en"
  }, {
    path: "/de/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus","de":"\u002Fabout-us","fr":"\u002Fabout-us","it":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___de"
  }, {
    path: "/de/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera","de":"\u002Fcareer","fr":"\u002Fcareer","it":"\u002Fcareer"},"page_id":21},
    name: "career-21___de"
  }, {
    path: "/de/electronics-assembly",
    component: _71380443,
    meta: {"paths":{"en":"\u002Felectronics-assembly","lt":"\u002Fkontraktine-gamyba","de":"\u002Felectronics-assembly","fr":"\u002Felectronics-assembly","it":"\u002Felectronics-assembly"},"page_id":39},
    name: "electronics-assembly-39___de"
  }, {
    path: "/de/electronics-design",
    component: _4116c95b,
    meta: {"paths":{"en":"\u002Felectronics-design","lt":"\u002Felektronikos-dizainas","de":"\u002Felectronics-design","fr":"\u002Felectronics-design","it":"\u002Felectronics-design"},"page_id":38},
    name: "electronics-design-38___de"
  }, {
    path: "/de/ems-rma",
    component: _90b558e6,
    meta: {"paths":{"en":"\u002Fems-rma","lt":"\u002Fems-rma","de":"\u002Fems-rma","fr":"\u002Fems-rma","it":"\u002Fems-rma"},"page_id":44},
    name: "ems-rma-44___de"
  }, {
    path: "/de/manufacturing",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fmanufacturing","lt":"\u002Fgamyba","de":"\u002Fmanufacturing","fr":"\u002Fmanufacturing","it":"\u002Fmanufacturing"},"page_id":40},
    name: "manufacturing-40___de"
  }, {
    path: "/de/manufacturing-journey",
    component: _56a47c2c,
    meta: {"paths":{"en":"\u002Fmanufacturing-journey","lt":"\u002Felektronikos-gamybos-kelias","de":"\u002Fmanufacturing-journey","fr":"\u002Fmanufacturing-journey","it":"\u002Fmanufacturing-journey"},"page_id":180},
    name: "manufacturing-journey-180___de"
  }, {
    path: "/de/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1","de":"\u002Fnews","fr":"\u002Fnews","it":"\u002Fnews"},"page_id":7},
    name: "news-7___de"
  }, {
    path: "/de/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos","de":"\u002Fnewsroom","fr":"\u002Fnewsroom","it":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___de"
  }, {
    path: "/de/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai","de":"\u002Fresources","fr":"\u002Fresources","it":"\u002Fresources"},"page_id":28},
    name: "resources-28___de"
  }, {
    path: "/de/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska","de":"\u002Fsearch","fr":"\u002Fsearch","it":"\u002Fsearch"},"page_id":45},
    name: "search-45___de"
  }, {
    path: "/de/services",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fservices","lt":"\u002Fpaslaugos","de":"\u002Fservices","fr":"\u002Fservices","it":"\u002Fservices"},"page_id":29},
    name: "services-29___de"
  }, {
    path: "/de/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok","de":"\u002Fsubscribe","fr":"\u002Fsubscribe","it":"\u002Fsubscribe"},"page_id":67},
    name: "subscribe-67___de"
  }, {
    path: "/de/subscribe-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old","de":"\u002Fsubscribe-old","fr":"\u002Fsubscribe-old","it":"\u002Fsubscribe-old"},"page_id":13},
    name: "subscribe-old-13___de"
  }, {
    path: "/de/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___de"
  }, {
    path: "/fr/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus","de":"\u002Fabout-us","fr":"\u002Fabout-us","it":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___fr"
  }, {
    path: "/fr/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera","de":"\u002Fcareer","fr":"\u002Fcareer","it":"\u002Fcareer"},"page_id":21},
    name: "career-21___fr"
  }, {
    path: "/fr/electronics-assembly",
    component: _71380443,
    meta: {"paths":{"en":"\u002Felectronics-assembly","lt":"\u002Fkontraktine-gamyba","de":"\u002Felectronics-assembly","fr":"\u002Felectronics-assembly","it":"\u002Felectronics-assembly"},"page_id":39},
    name: "electronics-assembly-39___fr"
  }, {
    path: "/fr/electronics-design",
    component: _4116c95b,
    meta: {"paths":{"en":"\u002Felectronics-design","lt":"\u002Felektronikos-dizainas","de":"\u002Felectronics-design","fr":"\u002Felectronics-design","it":"\u002Felectronics-design"},"page_id":38},
    name: "electronics-design-38___fr"
  }, {
    path: "/fr/ems-rma",
    component: _90b558e6,
    meta: {"paths":{"en":"\u002Fems-rma","lt":"\u002Fems-rma","de":"\u002Fems-rma","fr":"\u002Fems-rma","it":"\u002Fems-rma"},"page_id":44},
    name: "ems-rma-44___fr"
  }, {
    path: "/fr/manufacturing",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fmanufacturing","lt":"\u002Fgamyba","de":"\u002Fmanufacturing","fr":"\u002Fmanufacturing","it":"\u002Fmanufacturing"},"page_id":40},
    name: "manufacturing-40___fr"
  }, {
    path: "/fr/manufacturing-journey",
    component: _56a47c2c,
    meta: {"paths":{"en":"\u002Fmanufacturing-journey","lt":"\u002Felektronikos-gamybos-kelias","de":"\u002Fmanufacturing-journey","fr":"\u002Fmanufacturing-journey","it":"\u002Fmanufacturing-journey"},"page_id":180},
    name: "manufacturing-journey-180___fr"
  }, {
    path: "/fr/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1","de":"\u002Fnews","fr":"\u002Fnews","it":"\u002Fnews"},"page_id":7},
    name: "news-7___fr"
  }, {
    path: "/fr/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos","de":"\u002Fnewsroom","fr":"\u002Fnewsroom","it":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___fr"
  }, {
    path: "/fr/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai","de":"\u002Fresources","fr":"\u002Fresources","it":"\u002Fresources"},"page_id":28},
    name: "resources-28___fr"
  }, {
    path: "/fr/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska","de":"\u002Fsearch","fr":"\u002Fsearch","it":"\u002Fsearch"},"page_id":45},
    name: "search-45___fr"
  }, {
    path: "/fr/services",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fservices","lt":"\u002Fpaslaugos","de":"\u002Fservices","fr":"\u002Fservices","it":"\u002Fservices"},"page_id":29},
    name: "services-29___fr"
  }, {
    path: "/fr/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok","de":"\u002Fsubscribe","fr":"\u002Fsubscribe","it":"\u002Fsubscribe"},"page_id":67},
    name: "subscribe-67___fr"
  }, {
    path: "/fr/subscribe-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old","de":"\u002Fsubscribe-old","fr":"\u002Fsubscribe-old","it":"\u002Fsubscribe-old"},"page_id":13},
    name: "subscribe-old-13___fr"
  }, {
    path: "/fr/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___fr"
  }, {
    path: "/it/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus","de":"\u002Fabout-us","fr":"\u002Fabout-us","it":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___it"
  }, {
    path: "/it/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera","de":"\u002Fcareer","fr":"\u002Fcareer","it":"\u002Fcareer"},"page_id":21},
    name: "career-21___it"
  }, {
    path: "/it/electronics-assembly",
    component: _71380443,
    meta: {"paths":{"en":"\u002Felectronics-assembly","lt":"\u002Fkontraktine-gamyba","de":"\u002Felectronics-assembly","fr":"\u002Felectronics-assembly","it":"\u002Felectronics-assembly"},"page_id":39},
    name: "electronics-assembly-39___it"
  }, {
    path: "/it/electronics-design",
    component: _4116c95b,
    meta: {"paths":{"en":"\u002Felectronics-design","lt":"\u002Felektronikos-dizainas","de":"\u002Felectronics-design","fr":"\u002Felectronics-design","it":"\u002Felectronics-design"},"page_id":38},
    name: "electronics-design-38___it"
  }, {
    path: "/it/ems-rma",
    component: _90b558e6,
    meta: {"paths":{"en":"\u002Fems-rma","lt":"\u002Fems-rma","de":"\u002Fems-rma","fr":"\u002Fems-rma","it":"\u002Fems-rma"},"page_id":44},
    name: "ems-rma-44___it"
  }, {
    path: "/it/manufacturing",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fmanufacturing","lt":"\u002Fgamyba","de":"\u002Fmanufacturing","fr":"\u002Fmanufacturing","it":"\u002Fmanufacturing"},"page_id":40},
    name: "manufacturing-40___it"
  }, {
    path: "/it/manufacturing-journey",
    component: _56a47c2c,
    meta: {"paths":{"en":"\u002Fmanufacturing-journey","lt":"\u002Felektronikos-gamybos-kelias","de":"\u002Fmanufacturing-journey","fr":"\u002Fmanufacturing-journey","it":"\u002Fmanufacturing-journey"},"page_id":180},
    name: "manufacturing-journey-180___it"
  }, {
    path: "/it/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1","de":"\u002Fnews","fr":"\u002Fnews","it":"\u002Fnews"},"page_id":7},
    name: "news-7___it"
  }, {
    path: "/it/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos","de":"\u002Fnewsroom","fr":"\u002Fnewsroom","it":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___it"
  }, {
    path: "/it/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai","de":"\u002Fresources","fr":"\u002Fresources","it":"\u002Fresources"},"page_id":28},
    name: "resources-28___it"
  }, {
    path: "/it/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska","de":"\u002Fsearch","fr":"\u002Fsearch","it":"\u002Fsearch"},"page_id":45},
    name: "search-45___it"
  }, {
    path: "/it/services",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fservices","lt":"\u002Fpaslaugos","de":"\u002Fservices","fr":"\u002Fservices","it":"\u002Fservices"},"page_id":29},
    name: "services-29___it"
  }, {
    path: "/it/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok","de":"\u002Fsubscribe","fr":"\u002Fsubscribe","it":"\u002Fsubscribe"},"page_id":67},
    name: "subscribe-67___it"
  }, {
    path: "/it/subscribe-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old","de":"\u002Fsubscribe-old","fr":"\u002Fsubscribe-old","it":"\u002Fsubscribe-old"},"page_id":13},
    name: "subscribe-old-13___it"
  }, {
    path: "/it/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___it"
  }, {
    path: "/lt/apie-mus",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus","de":"\u002Fabout-us","fr":"\u002Fabout-us","it":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___lt"
  }, {
    path: "/lt/karjera",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera","de":"\u002Fcareer","fr":"\u002Fcareer","it":"\u002Fcareer"},"page_id":21},
    name: "career-21___lt"
  }, {
    path: "/lt/kontraktine-gamyba",
    component: _71380443,
    meta: {"paths":{"en":"\u002Felectronics-assembly","lt":"\u002Fkontraktine-gamyba","de":"\u002Felectronics-assembly","fr":"\u002Felectronics-assembly","it":"\u002Felectronics-assembly"},"page_id":39},
    name: "electronics-assembly-39___lt"
  }, {
    path: "/lt/elektronikos-dizainas",
    component: _4116c95b,
    meta: {"paths":{"en":"\u002Felectronics-design","lt":"\u002Felektronikos-dizainas","de":"\u002Felectronics-design","fr":"\u002Felectronics-design","it":"\u002Felectronics-design"},"page_id":38},
    name: "electronics-design-38___lt"
  }, {
    path: "/lt/ems-rma",
    component: _90b558e6,
    meta: {"paths":{"en":"\u002Fems-rma","lt":"\u002Fems-rma","de":"\u002Fems-rma","fr":"\u002Fems-rma","it":"\u002Fems-rma"},"page_id":44},
    name: "ems-rma-44___lt"
  }, {
    path: "/lt/gamyba",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fmanufacturing","lt":"\u002Fgamyba","de":"\u002Fmanufacturing","fr":"\u002Fmanufacturing","it":"\u002Fmanufacturing"},"page_id":40},
    name: "manufacturing-40___lt"
  }, {
    path: "/lt/elektronikos-gamybos-kelias",
    component: _56a47c2c,
    meta: {"paths":{"en":"\u002Fmanufacturing-journey","lt":"\u002Felektronikos-gamybos-kelias","de":"\u002Fmanufacturing-journey","fr":"\u002Fmanufacturing-journey","it":"\u002Fmanufacturing-journey"},"page_id":180},
    name: "manufacturing-journey-180___lt"
  }, {
    path: "/lt/naujienos-1",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1","de":"\u002Fnews","fr":"\u002Fnews","it":"\u002Fnews"},"page_id":7},
    name: "news-7___lt"
  }, {
    path: "/lt/naujienos",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos","de":"\u002Fnewsroom","fr":"\u002Fnewsroom","it":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___lt"
  }, {
    path: "/lt/resursai",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai","de":"\u002Fresources","fr":"\u002Fresources","it":"\u002Fresources"},"page_id":28},
    name: "resources-28___lt"
  }, {
    path: "/lt/paieska",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska","de":"\u002Fsearch","fr":"\u002Fsearch","it":"\u002Fsearch"},"page_id":45},
    name: "search-45___lt"
  }, {
    path: "/lt/paslaugos",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fservices","lt":"\u002Fpaslaugos","de":"\u002Fservices","fr":"\u002Fservices","it":"\u002Fservices"},"page_id":29},
    name: "services-29___lt"
  }, {
    path: "/lt/prenumeruok",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok","de":"\u002Fsubscribe","fr":"\u002Fsubscribe","it":"\u002Fsubscribe"},"page_id":67},
    name: "subscribe-67___lt"
  }, {
    path: "/lt/prenumeruoti-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old","de":"\u002Fsubscribe-old","fr":"\u002Fsubscribe-old","it":"\u002Fsubscribe-old"},"page_id":13},
    name: "subscribe-old-13___lt"
  }, {
    path: "/lt/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___lt"
  }, {
    path: "/resources/warranty-repair",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fresources\u002Fwarranty-repair","lt":"\u002Fresursai\u002Fwarranty-repair","de":"\u002Fresources\u002Fwarranty-repair","fr":"\u002Fresources\u002Fwarranty-repair","it":"\u002Fresources\u002Fwarranty-repair"},"page_id":204},
    name: "resources-warranty-repair-204___en"
  }, {
    path: "/de/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","de":"\u002Fabout-us\u002Fcontacts","fr":"\u002Fabout-us\u002Fcontacts","it":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___de"
  }, {
    path: "/de/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","de":"\u002Fabout-us\u002Fmission-vision-values","fr":"\u002Fabout-us\u002Fmission-vision-values","it":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___de"
  }, {
    path: "/de/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","de":"\u002Fabout-us\u002Fpolicies-certificates","fr":"\u002Fabout-us\u002Fpolicies-certificates","it":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___de"
  }, {
    path: "/de/about-us/who-we-are",
    component: _167ccd54,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fkas-mes-esame","de":"\u002Fabout-us\u002Fwho-we-are","fr":"\u002Fabout-us\u002Fwho-we-are","it":"\u002Fabout-us\u002Fwho-we-are"},"page_id":37},
    name: "about-us-who-we-are-37___de"
  }, {
    path: "/de/resources/warranty-repair",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fresources\u002Fwarranty-repair","lt":"\u002Fresursai\u002Fwarranty-repair","de":"\u002Fresources\u002Fwarranty-repair","fr":"\u002Fresources\u002Fwarranty-repair","it":"\u002Fresources\u002Fwarranty-repair"},"page_id":204},
    name: "resources-warranty-repair-204___de"
  }, {
    path: "/fr/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","de":"\u002Fabout-us\u002Fcontacts","fr":"\u002Fabout-us\u002Fcontacts","it":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___fr"
  }, {
    path: "/fr/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","de":"\u002Fabout-us\u002Fmission-vision-values","fr":"\u002Fabout-us\u002Fmission-vision-values","it":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___fr"
  }, {
    path: "/fr/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","de":"\u002Fabout-us\u002Fpolicies-certificates","fr":"\u002Fabout-us\u002Fpolicies-certificates","it":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___fr"
  }, {
    path: "/fr/about-us/who-we-are",
    component: _167ccd54,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fkas-mes-esame","de":"\u002Fabout-us\u002Fwho-we-are","fr":"\u002Fabout-us\u002Fwho-we-are","it":"\u002Fabout-us\u002Fwho-we-are"},"page_id":37},
    name: "about-us-who-we-are-37___fr"
  }, {
    path: "/fr/resources/warranty-repair",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fresources\u002Fwarranty-repair","lt":"\u002Fresursai\u002Fwarranty-repair","de":"\u002Fresources\u002Fwarranty-repair","fr":"\u002Fresources\u002Fwarranty-repair","it":"\u002Fresources\u002Fwarranty-repair"},"page_id":204},
    name: "resources-warranty-repair-204___fr"
  }, {
    path: "/it/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","de":"\u002Fabout-us\u002Fcontacts","fr":"\u002Fabout-us\u002Fcontacts","it":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___it"
  }, {
    path: "/it/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","de":"\u002Fabout-us\u002Fmission-vision-values","fr":"\u002Fabout-us\u002Fmission-vision-values","it":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___it"
  }, {
    path: "/it/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","de":"\u002Fabout-us\u002Fpolicies-certificates","fr":"\u002Fabout-us\u002Fpolicies-certificates","it":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___it"
  }, {
    path: "/it/about-us/who-we-are",
    component: _167ccd54,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fkas-mes-esame","de":"\u002Fabout-us\u002Fwho-we-are","fr":"\u002Fabout-us\u002Fwho-we-are","it":"\u002Fabout-us\u002Fwho-we-are"},"page_id":37},
    name: "about-us-who-we-are-37___it"
  }, {
    path: "/it/resources/warranty-repair",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fresources\u002Fwarranty-repair","lt":"\u002Fresursai\u002Fwarranty-repair","de":"\u002Fresources\u002Fwarranty-repair","fr":"\u002Fresources\u002Fwarranty-repair","it":"\u002Fresources\u002Fwarranty-repair"},"page_id":204},
    name: "resources-warranty-repair-204___it"
  }, {
    path: "/lt/apie-mus/kontaktai",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","de":"\u002Fabout-us\u002Fcontacts","fr":"\u002Fabout-us\u002Fcontacts","it":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___lt"
  }, {
    path: "/lt/apie-mus/misija-vizija-ir-vertybes",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","de":"\u002Fabout-us\u002Fmission-vision-values","fr":"\u002Fabout-us\u002Fmission-vision-values","it":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___lt"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","de":"\u002Fabout-us\u002Fpolicies-certificates","fr":"\u002Fabout-us\u002Fpolicies-certificates","it":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___lt"
  }, {
    path: "/lt/apie-mus/kas-mes-esame",
    component: _167ccd54,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fkas-mes-esame","de":"\u002Fabout-us\u002Fwho-we-are","fr":"\u002Fabout-us\u002Fwho-we-are","it":"\u002Fabout-us\u002Fwho-we-are"},"page_id":37},
    name: "about-us-who-we-are-37___lt"
  }, {
    path: "/lt/resursai/warranty-repair",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fresources\u002Fwarranty-repair","lt":"\u002Fresursai\u002Fwarranty-repair","de":"\u002Fresources\u002Fwarranty-repair","fr":"\u002Fresources\u002Fwarranty-repair","it":"\u002Fresources\u002Fwarranty-repair"},"page_id":204},
    name: "resources-warranty-repair-204___lt"
  }, {
    path: "/",
    component: _2029e94b,
    meta: {"paths":{"en":"\u002F","lt":"\u002F","de":"\u002F","fr":"\u002F","it":"\u002F"},"page_id":6},
    name: "teltonika-ems.com-landing-6___en"
  }, {
    path: "/de/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","de":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","fr":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","it":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___de"
  }, {
    path: "/fr/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","de":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","fr":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","it":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___fr"
  }, {
    path: "/it/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","de":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","fr":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","it":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___it"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","de":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","fr":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","it":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___lt"
  }, {
    path: "/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","de":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","fr":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","it":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___en"
  }, {
    path: "/de/manufacturing-journey/:slug",
    component: _54eddae4,
    meta: {"paths":{"en":"\u002Fmanufacturing-journey\u002F:slug","lt":"\u002Felektronikos-gamybos-kelias\u002F:slug","de":"\u002Fmanufacturing-journey\u002F:slug","fr":"\u002Fmanufacturing-journey\u002F:slug","it":"\u002Fmanufacturing-journey\u002F:slug"},"page_id":180},
    name: "manufacturing-journey-:slug-180-slug___de"
  }, {
    path: "/de/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","de":"\u002Fnewsroom\u002F:slug","fr":"\u002Fnewsroom\u002F:slug","it":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___de"
  }, {
    path: "/fr/manufacturing-journey/:slug",
    component: _54eddae4,
    meta: {"paths":{"en":"\u002Fmanufacturing-journey\u002F:slug","lt":"\u002Felektronikos-gamybos-kelias\u002F:slug","de":"\u002Fmanufacturing-journey\u002F:slug","fr":"\u002Fmanufacturing-journey\u002F:slug","it":"\u002Fmanufacturing-journey\u002F:slug"},"page_id":180},
    name: "manufacturing-journey-:slug-180-slug___fr"
  }, {
    path: "/fr/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","de":"\u002Fnewsroom\u002F:slug","fr":"\u002Fnewsroom\u002F:slug","it":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___fr"
  }, {
    path: "/it/manufacturing-journey/:slug",
    component: _54eddae4,
    meta: {"paths":{"en":"\u002Fmanufacturing-journey\u002F:slug","lt":"\u002Felektronikos-gamybos-kelias\u002F:slug","de":"\u002Fmanufacturing-journey\u002F:slug","fr":"\u002Fmanufacturing-journey\u002F:slug","it":"\u002Fmanufacturing-journey\u002F:slug"},"page_id":180},
    name: "manufacturing-journey-:slug-180-slug___it"
  }, {
    path: "/it/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","de":"\u002Fnewsroom\u002F:slug","fr":"\u002Fnewsroom\u002F:slug","it":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___it"
  }, {
    path: "/lt/elektronikos-gamybos-kelias/:slug",
    component: _54eddae4,
    meta: {"paths":{"en":"\u002Fmanufacturing-journey\u002F:slug","lt":"\u002Felektronikos-gamybos-kelias\u002F:slug","de":"\u002Fmanufacturing-journey\u002F:slug","fr":"\u002Fmanufacturing-journey\u002F:slug","it":"\u002Fmanufacturing-journey\u002F:slug"},"page_id":180},
    name: "manufacturing-journey-:slug-180-slug___lt"
  }, {
    path: "/lt/naujienos/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","de":"\u002Fnewsroom\u002F:slug","fr":"\u002Fnewsroom\u002F:slug","it":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___lt"
  }, {
    path: "/manufacturing-journey/:slug",
    component: _54eddae4,
    meta: {"paths":{"en":"\u002Fmanufacturing-journey\u002F:slug","lt":"\u002Felektronikos-gamybos-kelias\u002F:slug","de":"\u002Fmanufacturing-journey\u002F:slug","fr":"\u002Fmanufacturing-journey\u002F:slug","it":"\u002Fmanufacturing-journey\u002F:slug"},"page_id":180},
    name: "manufacturing-journey-:slug-180-slug___en"
  }, {
    path: "/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","de":"\u002Fnewsroom\u002F:slug","fr":"\u002Fnewsroom\u002F:slug","it":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
